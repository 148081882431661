import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useJob } from '../components/JobProvider'
import FileUploader from '../components/FileUploader';
import UploadedFileView from '../components/UploadedFileView';
import ModalMaterialsWelcome from '../components/ModalMaterialsWelcome';
import styles from './Upload.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


export default function SpycareUpload() {
    const [uploadData, setUploadData] = useState([]);
    const { search } = useLocation();
    const [clientName, setClientName] = useState(null);
    const { spycareJobId: spycareJob } = useJob()
    const [spycareJobObject, setSpycareJobObject] = useState();

    const token = '5dc2161a-b9e3-49fe-809a-d8d01c831fff';

    const queryString = (React.useMemo(() => new URLSearchParams(search), [search])).get("h");

    const handleFileUploaderCallback = (data) => {
        const formData = new FormData();
		formData.append("jobId", spycareJobObject.spycareJobId);
		for(let i = 0; i < data.length; i++){
			formData.append("files[" + i + "]", data[i]);
			formData.append("documentNames[" + i + "]", data[i].name);
		}

        fetch(`${process.env.REACT_APP_API_URL}/data/spycare/multipart_document`, {
            body: formData,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.json())
        .then(docs => {
            const uploadedDocs = [];
            docs.map(d => uploadedDocs.push({id: d.spycareDocumentId, name: d.fileName, size: d.size, url: d.url}));
            setUploadData(uploadedDocs);
        },
        (error) => {
            console.log(error);
        });
    }

    const handleFileViewCallback = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}/data/spycare/document/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json)
        .then(() => {
            setUploadData(uploadData.filter(file => file.id !== id));
        })
    }

    // Pull the job data from the hash provided in the query string or use the currently logged in user's job id
    useEffect(() => {
        if (queryString) {
            fetch(`${process.env.REACT_APP_API_URL}/data/spycare/audit_material/${queryString}`, {
                headers: {
                         Authorization: `Bearer ${token}`
                     }
            })
            .then(res => res.json())
            .then(
                (result) => {
                setClientName(result.job.client?.clientName);
                setSpycareJobObject(result.job);
            },
                (error) => {
                    console.log(error);
                }
            )
        } else if (spycareJob) {
            fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${spycareJob}` , {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setClientName(result.job.client?.clientName);
                    setSpycareJobObject(result)
                }
            )
        }
    }, [queryString, spycareJob]);

    return (
        <>
        <ModalMaterialsWelcome companyName={clientName}/>
            <Container className="mt-3">
                <Row>
                    <Col xs={12} className="d-flex justify-content-between align-content-center">
                        <h1 className="mb-0">SpyGlass Materials Upload</h1>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-3 mb-3">
                <Row>
                    <Col xs={12} lg={3} className="mt-lg-0">
                        <Card className="card-h-100">
                            <Card.Body>
                            <div className="cardTitleContainer">
                                <Card.Title className="d-flex align-items-center">
                                    <div className="circleNumber me-2 bg-primary color-white">1</div> Select Your Files
                                </Card.Title>
                            </div>
                            <Card.Text>
                            Drag and Drop your files into the blue box below or alternatively,
                        click the white “Choose Files” button to select the files you want to attach.
                        Select more than one file by holding Shift or Ctrl as you select. Once your selection is made, click Open.
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={3} className="mt-3 mt-lg-0">
                        <Card className="card-h-100">
                            <Card.Body>
                                <div className="cardTitleContainer">
                                    <Card.Title className="d-flex align-items-center">
                                        <div className="circleNumber me-2 bg-primary color-white">2</div>
                                        <span className="ls-n3">Review Your Selected Files</span>
                                    </Card.Title>
                                </div>
                                <Card.Text>
                                    The files to be uploaded will be displayed below. Review for accuracy.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={3} className="mt-3 mt-lg-0">
                        <Card className="card-h-100">
                            <Card.Body>
                                <div className="cardTitleContainer">
                                    <Card.Title className="d-flex align-items-center">
                                        <div className="circleNumber me-2 bg-primary color-white">3</div>
                                        Upload Your Files
                                    </Card.Title>
                                </div>
                                <Card.Text>
                                    Select the blue “Upload” button to submit the files to SpyGlass.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={3} className="mt-3 mt-lg-0">
                        <Card className="card-h-100">
                            <Card.Body>
                                <div className="cardTitleContainer">
                                    <Card.Title className="d-flex align-items-center">
                                        <div className="circleNumber me-2 bg-primary color-white">4</div>
                                        Confirm Your Uploads
                                    </Card.Title>
                                </div>
                                <Card.Text>
                                    Wait for the upload progress bar to complete and the confirmation message to display.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <div className="cardTitleContainer">
                                    <Card.Title>File Upload</Card.Title>
                                </div>
                                <Card.Text>
                                    <span className="color-warning pe-1"><FontAwesomeIcon icon="exclamation-circle" /></span>The total size of the files must be less than 1 GB for each attempt. You can upload more than 1 GB with multiple attempts
                                </Card.Text>
                                <FileUploader callback={handleFileUploaderCallback} />
                                    {uploadData.length > 0
                                    ? <>
                                        <h3>Uploaded Successfully</h3>
                                        <div className={styles.uploadedFileContainer}>
                                            {uploadData.map((file, index) => <UploadedFileView callback={handleFileViewCallback} file={file} key={index} />
                                            )}
                                        </div>
                                    </>
                                    : <></>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={styles.checkListSection}>
                  <Col>
                    <h1>Audit Materials Checklist</h1>
                    <br />
                    <p><b>IMPORTANT:</b> Online access is preferred for most of the billing types required to perform our audit. If electronic bills are provided, it is critical that all pages are included, front and back, including any supporting pages.</p>
                    <h4 style={{marginBottom: 0}}>Network Services</h4>
                    <div>Billing from the most recent two month period for all services at all locations.
                        <ul>
                          <li>Voice Services (Traditional telephone, VoIP, SIP, PRI, Analog, etc.)</li>
                          <li>Internet Services (WAN, MPLS, Fiber, SD-Wan, etc.)</li>
                          <li>Data (Data Center, Co-Location, Disaster Recover, Fiber, etc.)</li>
                          <li>Cable Services</li>
                        </ul>
                    </div>
                    <h4>Wireless/Mobile Services</h4>
                    <div>Online portal access for wireless/mobile accounts.
                        <ul>
                          <li><b>Best:</b> See step by step instructions provided in the links below for Verizon, AT&T, and T-Mobile online access. Important to complete steps for all logins or accounts you have (for example, some clients have multiple logins to Verizon Wireless and the different accounts are not linked). For all other wireless providers (i.e. Rogers) portal access is not required and billing from the most recent two month period will suffice.
                            <ul>
                              <li><a href="https://sway.cloud.microsoft/KGBsCRQHi1x1Avgc?ref=Link">Verizon Wireless Portal Instructions</a></li>
                              <li><a href="https://sway.cloud.microsoft/oyKK1y2AtWIDlHZF?ref=Link">AT&T Premier Wireless Portal Instructions</a></li>
                              <li><a href="https://sway.cloud.microsoft/qBr58DJwWQ8ZuXxn?ref=Link">AT&T FirstNet Wireless Portal Instructions</a></li>
                            </ul>
                          </li>
                          <li><b>Minimally Acceptable:</b> Only If clients do not have online portal access established, upload invoices from the most recent two month period. SpyGlass will discuss establishing online access during the kickoff meeting.</li>
                        </ul>
                    </div>
                    <h4>Cloud Services and SaaS Licensing</h4>
                    <div>Online access preferred or two months billing.
                        <ul>
                          <li>
                            Cloud Computing Big Three (AWS, Microsoft Azure, Google Cloud)
                            <ul>
                              <li>Your Cloud billing may come through MSP or 3rd party and not directly from AWS, Azure, etc. Once SpyGlass identifies how it is billed, any additional instructions to link Cloud billing portal to SpyGlass' analytical tools will be provided.</li>
                            </ul>
                          </li>
                          <li>Productivity Tools (Office 365, Teams and Zoom Voice)</li>
                        </ul>
                    </div>
                    <div className="spacer">
                      <h4>Vendor Contracts</h4>
                      <p>Corresponding contracts that apply to the above services.</p>
                    </div>
                    <div className="spacer">
                      <h4>LOA</h4>
                      <div>If not already provided, one signed Letter of Agency that allows SpyGlass to access information from the carriers required to perform the audit. Additional LOAs from specific providers may be necessary as well.</div>
                      <ul>
                        <li><a href="https://spyglassgroup.sharepoint.com/:w:/s/Shared/EW1W8TrrGD1BllucBrIFs8YBR9Klros_WZq718NcEps5pg?e=VzYX5J">Click here to download a copy of our template</a></li>
                      </ul>
                    </div>
                  </Col>
                </Row>
            </Container>
        </>
    );
}